import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableGPULoadable';
import Accordion from 'components/accordion';
import AccentBox from 'components/accentBox';
import BasicTable from "components/basicTable";
import Socials from 'components/socials';
import Meyasu from 'components/gpu/meyasu';
import GeforceMatsubi from 'components/gpu/geforce_matsubi';
import Image from "components/image";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "gpu/gpu-cospa"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/gpu/gpu-cospa.png"}) { ...eyecatchImg },
    allGpuCospaJson {
      nodes {
        name
        passmark
        vmark
        fullHd
        qhd
        fourK
        tdp
        price
        cospa
        year
        rank
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`【コスパ最強】おすすめGPUランキング`}</h1>
    <time itemProp="datePublished" dateTime="Sat Nov 16 2024 00:00:46 GMT+0900 (日本標準時)">更新日:2024年11月16日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="コスパ最強 おすすめのGPUランキング" mdxType="Image" />
    <p>{`GPUの演算能力はもちろん高価なCPUほど高くなるが、値段に比例して上がるわけではなく、特にハイエンドなGPUでは、価格の上昇の割にはパフォーマンスが伸び悩むといったケースも多い。`}</p>
    <p>{`そこで本サイトではGPUの総合的な性能を示すPassMarkのデータと価格情報をもとにコストパフォーマンスを計算し、ランキング形式で表示する。`}</p>
    <p>{`3DMarkや、重めのゲームで期待できるフレームレートの情報も併せて掲載したので比較してほしい。また、モバイル等も含めた全てのGPUデータを確認したい場合は`}<Link to="/gpu/" mdxType="Link">{`GPU性能比較表`}</Link>{`を参照してほしい。`}</p>
    <h2 {...{
      "id": "コスパ最強-GPUランキング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B3%E3%82%B9%E3%83%91%E6%9C%80%E5%BC%B7-GPU%E3%83%A9%E3%83%B3%E3%82%AD%E3%83%B3%E3%82%B0",
        "aria-label": "コスパ最強 GPUランキング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コスパ最強 GPUランキング`}</h2>
    <p>{`それではさっそく順位を提示する。表の見方やPassmarkスコアの目安なども示しておくので参考にしてほしい。`}</p>
    <Meyasu mdxType="Meyasu" />
    <Accordion title="GPU型番の種類・末尾の意味一覧" mdxType="Accordion">
      <p>グラフィックボードのメーカーはNVIDIAとAMDの２社であり、それぞれが用途に応じたGPUを製造している。また、オンボードグラフィックというパソコンの基盤であるマザーボードにGPU能力が付与されたものもあり、CPUメーカーのインテルなどが別途開発している。</p>
      <p>オンボードグラフィックも含め、有名なものは以下の通りである。</p>
      <AccentBox title="グラフィックボードの種類" mdxType="AccentBox">
  <li><span className="bold">NVIDIA Geforce</span>・・・NVIDIAのゲーム用に最適化されたGPU、安定したパフォーマンスを誇り、シェアが断トツ。低スペックなものからハイスペックなものまで幅広いモデルが存在する </li>
  <li><span className="bold">AMD Radeon</span>・・・AMD社のゲーム用に最適化されたGPU、Geforceとよく比較される、こちらも低スペックなものからハイスペックなものまで幅広いモデルが存在する</li>
  <li><span className="bold">Intel Arc Graphics</span>・・・IntelのGPUシリーズ。ディスクリートなものとオンボードのものがある。ローエンドが主だがグラフィック機能を適度に補完できる。</li>
      </AccentBox>
      <p>次にGeforceの末尾の意味については以下のようになる。</p>
      <GeforceMatsubi mdxType="GeforceMatsubi" />
    </Accordion>
    <Accordion title="検索/フィルタリング方法の例・詳細" mdxType="Accordion">
      <p>ヘッダー部分をクリックすることでソートが行える。また、GPU名称はカンマ区切りをすることでor条件で調べられ、各スコアはレンジ(-)や不等号でフィルタリングすることができる。</p>
      <AccentBox title="フィルター方法の例(該当するフォームへ入力)" mdxType="AccentBox">
  <li><span className="bold">RTX 40, GTX 10</span>・・・RTX 4000台とGTX 1000台を比較</li>
  <li><span className="bold">GeForce, Radeon</span>・・・GeforceとRadeonを比較</li>
  <li><span className="bold">5000 - 10000</span>・・・スコアが5000から10000まで、-の間にはスペースが必要</li>
  <li><span className="bold">{'<'}100</span>・・・TDPが100W未満の省電力グラフィックボードのみを表示</li>
  <li><span className="bold">{'>'}200</span>・・・参考価格が200ドルより上</li>
      </AccentBox>
    </Accordion>
    <AccentBox title="Passmarkの目安" mdxType="AccentBox">
  <li><span className="bold">25000~</span>・・・4K120FPSを目指したい時。レイトレーシングでもフレームレートを保ち続け最先端の映像体験を満喫。</li>
  <li><span className="bold">17000 - 25000</span>・・・4K60FPSやレイトレーシングの超美麗グラフィックや144Hzなど高FPSをコスパ良く体験。ゲーミングモニターが必須となる。</li>
  <li><span className="bold">11000 - 17000</span>・・・ほぼ全てのゲームをFullHD x 60FPSで遊ぶには十分な性能でVRにも対応。</li>
  <li><span className="bold">6000 - 11000</span>・・・ほぼ全てのゲームをFullHDで遊ぶことができるが画質調整は必要。VRにはやや力不足。</li>
  <li><span className="bold">3000 - 6000</span>・・・解像度やレンダリングのクオリティを十分に落とせば大体のゲームは遊べるが、ゲーミングPCは名乗れないレベル。</li>
  <li><span className="bold">0 - 3000</span>・・・あえて言おう、カスであると。マインクラフトやドラクエXなど軽いゲームなら遊べるものもあるが、多くのゲームは全くできないか最低画質でもカックカクになる。</li>
    </AccentBox>
    <HikakuTable {...props} checkboxes={['geforce', 'radeon']} cpuData={props.data.allGpuCospaJson.nodes} sort={{
      fieldName: 'cospa'
    }} storeLink mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      